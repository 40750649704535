<template>
  <div>

    <div class="form">

      <el-form label-width="90px"
               :hide-required-asterisk="true"
               ref="add_form"
               :model="add_form"
               :rules="add_rules" >
        <div class="content">
          <el-form-item label="项目名称" prop="itemName">
            <el-input maxlength="30" v-model="add_form.itemName" placeholder="请输入项目名称"></el-input>
          </el-form-item>
          <el-form-item label="项目金额" prop="itemPrice">
            <el-input  v-model="add_form.itemPrice" placeholder="请输入项目金额"></el-input>
          </el-form-item>
          <el-form-item label="适用性别" prop="fitGender">
            <el-select v-model="add_form.fitGender" clearable placeholder="请选择适用性别">
              <el-option v-for="item in $store.state.fitGender"
                         :key="item.dictKey"
                         :label="item.dictValue"
                         :value="item.dictKey"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="重复项目" prop="duplicateItemList">
            <el-select v-model="add_form.duplicateItemList"
                       multiple
                       collapse-tags
                       clearable placeholder="请选择重复项目">
              <el-option-group
                  v-for="(group,index) in item"
                  :key="index"
                  :label="group.groupName">
                <el-option
                    v-for="item in group.itemList"
                    :key="item.itemId"
                    :label="item.itemName"
                    :value="item.itemId">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item label="项目类型" prop="itemType">
            <el-select v-model="add_form.itemType" clearable placeholder="请选择项目类型">
              <el-option v-for="item in $store.state.itemType"
                         :key="item.dictKey"
                         :label="item.dictValue"
                         :value="item.dictKey"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="附加项类型" prop="itemPlusType">
            <el-select v-model="add_form.itemPlusType" clearable placeholder="请选择项目类型">
              <el-option v-for="item in $store.state.itemPlusType"
                         :key="item.dictKey"
                         :label="item.dictValue"
                         :value="item.dictKey"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品类别" prop="productCategory">
            <el-select v-model="add_form.productCategory" clearable placeholder="请选择产品类别">
              <el-option v-for="item in $store.state.category"
                         :key="item.dictKey"
                         :label="item.dictValue"
                         :value="item.dictKey"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="具体内容" prop="itemContent">
            <el-input type="textarea" :rows="12" v-model="add_form.itemContent" clearable placeholder="请输入具体内容"></el-input>
          </el-form-item>
          <el-form-item label="检查意义" prop="itemDesc">
            <el-input type="textarea" :rows="12" v-model="add_form.itemDesc" clearable placeholder="请输入项目描述"></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <div class="button">
            <el-button size="small" @click="addClose">取消</el-button>
            <el-button size="small" v-if="addT" @click="onSubmitAddT(add_form)">保存添加下一条</el-button>
            <el-button size="small" type="primary" @click="onSubmitAdd(add_form)" >确定保存</el-button>
          </div>
        </el-form-item>
      </el-form>

    </div>

  </div>
</template>

<script>
export default {
  name: "ProductExaDeta",
  data() {
    var validatorItemPrice = (rule, value, callback) => {
      if(value == 0) {
        callback(new Error("项目价格不能小于等于0"));
      }else {
        callback();
      }
    };
    return {
      // 添加编辑
      add_form: {
        itemName: null,
        productCategory: null,
        itemDesc: null,
        itemId: null,
        itemPrice: null,
        fitGender: null,
        itemContent: null,
        itemPlusType: null,
        duplicateItemList: []
      },
      add_rules: {
        itemName: [
          { required: true, message: '请输入项目名称', trigger: 'change' },
          { min: 0, max: 30, message: '项目名称长度在 0 到 30 位', trigger: 'blur' }
        ],
        itemType: [
          { required: true, message: '请选择项目类型', trigger: 'change' }
        ],
        itemPlusType: [
          { required: true, message: '请选择附加项类型', trigger: 'change' }
        ],
        productCategory: [
          { required: true, message: '请选择产品类别', trigger: 'change' }
        ],
        itemDesc: [
          { required: true, message: '请输入项目描述', trigger: 'change' }
        ],
        itemContent: [
          { required: true, message: '请输入具体内容', trigger: 'change' }
        ],
        itemPrice: [
          { required: true, message: '请输入项目价格', trigger: 'change' },
          { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '项目价格不正确' },
          { validator: validatorItemPrice, trigger: 'change' }
        ],
        fitGender: [
          { required: true, message: '请输入适用性别', trigger: 'change' }
        ],
      },
      addT: true,
      item: []
    }
  },
  created() {
    this.get_item();

    if(this.$route.query.id) {
      this.getDeta(this.$route.query.id)
    }

  },
  methods: {
    getDeta(e) {

      this.$get("product-item",e,true)
          .then(res => {
            if(res) {

              // 重复项目
              let obj= {};
              obj["itemId"]= e;
              this.get_item(obj)

              this.addT= false;
              this.$nextTick(()=> {
                this.add_form= res.data;
              })

            }
          })

    },
    // 重复项目
    get_item(y) {
      this.$get("product-item/select",y)
          .then(res => {
            if(res) {
              console.log(res)
              this.item= res.data;
            }
          })
    },
    // 保存添加下一条
    onSubmitAddT(formName) {
      this.$refs["add_form"].validate((valid) => {
        if(valid) {
          console.log(formName)

          this.$post("product-item",formName)
              .then(res => {
                if(res) {
                  this.add_form.itemId= null;
                  this.$refs["add_form"].resetFields();
                  this.get_item();
                }
              })

        }
      })
    },
    // 确认保存
    onSubmitAdd(formName) {
      this.$refs["add_form"].validate((valid) => {
        if(valid) {
          console.log(formName)

          if(formName.itemId) {
            this.$put("product-item",formName)
                .then(res => {
                  if(res){
                    this.addClose();
                  }
                })
          }else {
            this.$post("product-item",formName)
                .then(res => {
                  if(res) {
                    this.addClose()
                  }
                })
          }

        }
      })
    },
    addClose() {
      this.$router.go(-1);
    }
  }
}
</script>

<style lang="less" scoped>
    @import "../../style/Product/ProductDeta.less";
</style>
